h4 {
  color: rgba(0, 0, 0, 0.747);
}

.text-nocolor {
  font-size: 14px;
  color: rgba(123, 124, 126, 0.658);
}

@media only screen and (max-width: 576px) {
  form {
    max-width: 85%;
    margin: 0 auto;
  }

  form button {
    width: 100%;
  }

  .text-nocolor {
    font-size: 11px;
    color: rgba(123, 124, 126, 0.658);
  }
}
