/* Hover Navbar Buttons */

.hoverNavButtons {
  transition: 0.3s;
}

.hoverNavButtons:hover {
  color: rgb(145, 139, 139);
}

ul li span {
  font-weight: 400;
  font-size: 14px;
}
